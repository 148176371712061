import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";

export const BANNER_HEIGHTS = {
  small: {
    mobile: "180px",
    desktop: "280px",
  },
  medium: {
    mobile: "288px",
    desktop: "572px",
  },
} as const;

export const ExtraSmallBannerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SmallBannerWrapper = styled.div`
  height: 100%;
`;

export const MediumBannerWrapper = styled.div`
  height: 100%;
`;

type BannerWrapperProps = {
  isBannerFullWidth: boolean;
};

export const BannerWrapper = styled.li<BannerWrapperProps>(
  ({ isBannerFullWidth }) => css`
    position: relative;
    ${isBannerFullWidth && "grid-column: 1 / span 2;"}
  `
);

type BannerTypeProps = {
  isExtraSmall?: boolean;
};

export const CountdownTimeContainer = styled.div<BannerTypeProps>(
  ({ isExtraSmall }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spaces.mini};

    // TODO: We should consider have the same CountdownTimer everywhere or be able to configure it
    div > p > span {
      // Timeholder+SpaceHolder
      font-size: ${isExtraSmall ? "20px" : "26px"};

      ${MQ("tabletHorizontal")} {
        font-size: ${isExtraSmall ? "20px" : "34px"};
      }

      &:nth-of-type(odd) > span:first-of-type {
        // Timeholder
        min-width: ${isExtraSmall ? "20px" : "26px"};

        ${MQ("tabletHorizontal")} {
          min-width: ${isExtraSmall ? "20px" : "30px"};
        }
      }

      &:nth-of-type(even) {
        // Spaceholder
        margin: 0 4px;

        ${MQ("tabletHorizontal")} {
          margin: 0 8px;
        }
      }
    }

    div > p > span > span:last-of-type {
      // Text
      font-family: var(--font-family-regular);
      font-size: 10px;

      ${MQ("tabletHorizontal")} {
        font-size: ${isExtraSmall ? "10px" : "15px"};
      }
    }
  `
);
